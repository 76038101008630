import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import HomeProductCard from './HomeProductCard';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { get_products } from '../utils/common_requests';

function LatestRelease({ accessibilityState, isMobile }) {
    const [isLoading, setIsLoading] = useState(true);
    const [latestReleaseList, setLatestReleaseList] = useState([]);
    const scrollContainerRef = useRef(null);
	const [isUserScrolling, setIsUserScrolling] = useState(false);
	let scrollPosition = useRef(0);
    
    useEffect(() => {
		const fetchProducts = async () => {
            const prods = await get_products();    
            setLatestReleaseList(prods.filter((prod) => prod.latest_release == 1));
            if (prods !== undefined) {
                setIsLoading(false); 
            }
        };
        fetchProducts(); 
    }, []);

	// useEffect(() => {
	// 	if (accessibilityState) {
	// 		playVideo();
	// 	} else {
	// 		pauseVideo();
	// 	}
	// }, [accessibilityState]);


    const repeatCount = 2;
    let repeatedArray = [];
    for (let i = 0; i < repeatCount; i++) {
      	repeatedArray = repeatedArray.concat(latestReleaseList);
    }

	useLayoutEffect(() => {

        const scrollSpeed = 1; 
        let autoScrollInterval;
        let resumeScrollTimeout;

        // Flag to track if the scroll was programmatically initiated
        let isProgrammaticScroll = false;

        // Function to start auto-scrolling
        const startAutoScroll = () => {

            clearInterval(autoScrollInterval);
            autoScrollInterval = setInterval(() => {
                if (scrollContainerRef.current && !isUserScrolling) {
                    isProgrammaticScroll = true; // Mark as programmatic scroll
                    scrollPosition.current += scrollSpeed;
                    scrollContainerRef.current.scrollLeft = scrollPosition.current;

                    // Reset scroll position for continuous loop
                    if (scrollPosition.current >= scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth) {
                        scrollPosition.current = 0;
                    }
                }
            }, 20); // Adjust interval timing as needed
        };

        // Handle manual scroll event
        const handleUserScroll = () => {
            // Check if the scroll was programmatic
            if (isProgrammaticScroll) {
                isProgrammaticScroll = false; // Reset the flag
                return; // Skip user scroll handling
            }

            setIsUserScrolling(true);
            clearInterval(autoScrollInterval); // Stop auto-scroll
            clearTimeout(resumeScrollTimeout); // Clear any pending resume timeouts

            // Resume auto-scrolling after 2 seconds of no user scroll input
            resumeScrollTimeout = setTimeout(() => {
                setIsUserScrolling(false);
                startAutoScroll(); // Resume auto-scroll
				scrollPosition.current = scrollContainerRef.current.scrollLeft;
            }, 2000);
        };

        // Attach the onScroll event listener
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleUserScroll);
        }

        // Start the auto-scroll on initial render
        startAutoScroll();

        // Cleanup on component unmount
        return () => {
            clearInterval(autoScrollInterval);
            clearTimeout(resumeScrollTimeout);
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleUserScroll);
            }
        };
    }, [isUserScrolling, isLoading]);

	return (
		<>
			{isMobile ? (
				<Box
				sx={{
					overflow: "hidden",
					display:'flex',
					flexDirection:'column',
				}}
				>
				<Box
					sx={{
					bgcolor: "#0B0A0A",
					paddingY: "8px",
					borderTop: "1px solid white",
					borderBottom: "1px solid white",
					}}
				>
					<Typography
					variant="h5"
					sx={{
						color: "white",
						textAlign: "center",
						fontFamily: "Inter, sans-serif",
						fontWeight: "200",
						letterSpacing: ".05em",
					}}
					>
					LATEST RELEASE
					</Typography>
				</Box>
				{isLoading ? (
					<>
					<Skeleton variant="rectangular" width={210} height={118} />
					<Skeleton variant="rectangular" width={210} height={118} />
					<Skeleton variant="rectangular" width={210} height={118} />
					</>
				) : (
					// <Box
					// sx={{
					// 	backgroundColor: "#0B0A0A",
					// 	display: "flex",
					// 	justifyContent:'flex-end',
					// 	flexDirection: "row",
					// 	width: repeatedArray.length * 60 + "vw",
					// 	maxWidth: repeatedArray.length * 45 + "vh",
					// 	height: "60vw",
					// 	maxHeight: "45vh",
					// 	animation: "latestrelease 10s linear infinite",
					// 	"@keyframes latestrelease": {
					// 	"0%": {
					// 		transform: `translateX(-${100/repeatCount}%)`,
					// 	},
					// 	"100%": {
					// 		transform: `translateX(0%)`,
					// 	},
					// 	},
					// 	':hover': {
					// 	animationPlayState: 'paused',
					// 	},
					// 	animationPlayState: accessibilityState ? 'running' : 'paused',
					// }}
					// >
					// {repeatedArray.map((product, index) => (
					// 	<Box key={index} sx={{ aspectRatio: "1/1" }} padding={"10px"}>
					// 		<HomeProductCard product={product} />
					// 	</Box>
					// ))}
					// </Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid white' }}>
						<Box 
							ref={scrollContainerRef}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								width: '102vw',
								overflow: "scroll",
								scrollbarWidth: 'none',
								msOverflowStyle: 'none',
								'&::-webkit-scrollbar': {
									display: 'none',
								},
								borderBottom: '1px solid white',
							}}
						>
							{repeatedArray.map((product, index) => (
								<Box key={index} sx={{ 
									overflow: "hidden", 
									cursor: "pointer", 
									borderRight: '1px solid white',
									width: 'calc(33vw - 1px)',
									height: '33vw',
									maxWidth: '33vw',
									flexShrink: 0,
								}}>
									<HomeProductCard product={product} />
								</Box>
							))}
						</Box>
					</Box>
				)}
				</Box>
			) : (
				<Box
				sx={{
					overflow: "hidden",
					display:'flex',
					flexDirection:'column',
				}}
				>
				<Box
					sx={{
					bgcolor: "#0B0A0A",
					paddingY: "8px",
					borderTop: "1px solid white",
					borderBottom: "1px solid white",
					}}
				>
					<Typography
					variant="h5"
					sx={{
						color: "white",
						textAlign: "center",
						fontFamily: "Inter, sans-serif",
						fontWeight: "200",
						letterSpacing: ".05em",
					}}
					>
					LATEST RELEASE
					</Typography>
				</Box>
				{isLoading ? (
					<>
					<Skeleton variant="rectangular" width={210} height={118} />
					<Skeleton variant="rectangular" width={210} height={118} />
					<Skeleton variant="rectangular" width={210} height={118} />
					</>
				) : (
					<Box
					sx={{
						backgroundColor: "#0B0A0A",
						display: "flex",
						justifyContent:'flex-end',
						flexDirection: "row",
						width: repeatedArray.length * 60 + "vw",
						maxWidth: repeatedArray.length * 45 + "vh",
						height: "60vw",
						maxHeight: "45vh",
						animation: "latestrelease 20s linear infinite",
						"@keyframes latestrelease": {
						"0%": {
							transform: `translateX(-${100/repeatCount}%)`,
						},
						"100%": {
							transform: `translateX(0%)`,
						},
						},
						':hover': {
						animationPlayState: 'paused',
						},
						animationPlayState: accessibilityState ? 'running' : 'paused',
					}}
					>
					{repeatedArray.map((product, index) => (
						<Box key={index} sx={{ aspectRatio: "1/1" }} padding={"10px"}>
							<HomeProductCard product={product} />
						</Box>
					))}
					</Box>
				)}
				</Box>
			)}
		</>
	);
}

export default LatestRelease;
