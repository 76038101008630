import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Badge, Grid, Box, Drawer, Typography, Button } from '@mui/material';
import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import Marquee from './Marquee';
import { BsBagX } from "react-icons/bs";
import axios from 'axios';
import AspectRatio from '@mui/joy/AspectRatio';
import { TiDeleteOutline } from "react-icons/ti";
const NavBar = ({ cart, handleRemoveFromCart }) => {
    const navigate = useNavigate();
    const [cartLength, setCartLength] = useState(null);
    const [cartList, setCartList] = useState([]);
	const [totalPrice, setTotalPrice] = useState(0);
    const filePath =
  		process.env.NODE_ENV === 'production'
			? 'https://silverriot.co.uk/api/'
			: 'http://127.0.0.1/api/';
	
    useEffect(() => {
        const totalQuantity = cart.reduce((sum, item) => sum + (item.quantity || 0), 0);
        setCartLength(totalQuantity);
    }, [cart]);
    
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(filePath + 'get_cart.php',{ 'cart' : cart }, {
                    method: 'POST',
                    credentials: 'include',
                });
                setCartList(response.data);
                let totalPrice = response.data.reduce((total, item) => total + item.price * item.quantity, 0);
                setTotalPrice(totalPrice);
            } catch (error) {
                console.error('There was an error!', error);
            }
        };
        fetchData();
    }, [cart, filePath]);

    return (
        <AppBar position="static" style={{ backgroundColor: '#0B0A0A'}}>
            <Marquee text="FREE SHIPPING ON ALL ORDERS ACROSS THE UK" />
            <Toolbar>
                <Grid container>
                    <Grid item xs={5} container justifyContent="flex-start">
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'center', md:'start'}, alignItems:'center'}}>
                            <Box component={Link} to="/shop" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, fontFamily:'HelveticaNeue', paddingX:'10px' }}>
                                Shop
                            </Box>
                            <Box component={Link} to="/about" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, fontFamily:'HelveticaNeue', paddingX:'10px' }}>
                                About
                            </Box>
                            <Box component={Link} to="/contact" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, fontFamily:'HelveticaNeue', paddingX:'10px' }}>
                                Contact
                            </Box>
                            <Box component={Link} to="/info/delivery" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'}, fontFamily:'HelveticaNeue', paddingX:'10px' }}>
                                Info
                            </Box>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={2} container justifyContent="center">
                        <NavLink to="/home">
                            <img src="/images/logotext.svg" alt="logo" style={{ height: '50px', marginTop:'20px' }} />
                        </NavLink>
                    </Grid>
                    <Grid item xs={5} container justifyContent="flex-end" backgroundColor={"transparent"}>
                        <IconButton color="inherit" aria-label="shopping cart" onClick={toggleDrawer(true)}>
                            <Badge badgeContent={cartLength || 0} sx={{
                                "& .MuiBadge-badge": {
                                color: "white",
                                backgroundColor: "#721e1d",
                                margin:"0.3vw"
                                }
                            }}>
                                <ShoppingCartIcon style={{fontSize:'3vw'}}/>
                            </Badge>
                        </IconButton>
                        <Drawer open={drawerOpen} onClose={toggleDrawer(false)} anchor="right" PaperProps={{sx:{ height:'auto', width:"30%", backgroundColor:"#0B0A0A", border:"1px solid #721E1D"}}}>
                            <Box sx={{ height:'100%', display:'flex', flexDirection:'column'}}>
                                <Box display="flex" flexDirection="row">
                                    <Box 
                                    sx={{ 
                                        height:'108px',
                                        borderBottom: '1px solid white',
                                        marginLeft:'30px',
                                        marginBottom:'50px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width:'70%'
                                    }}>
                                        <Typography variant="h3" textAlign="center" color="white" fontFamily="Poppins" fontWeight="500" sx={{
                                            fontSize: "2vw",
                                        }}>
                                            Shopping Cart
                                        </Typography>
                                    </Box>
                                    <Box 
                                    component={Button}
                                    onClick={toggleDrawer(false)}
                                    sx={{
                                        width:'30%',
                                        display: 'flex',
                                        marginBottom:'50px',
                                    }}>
                                        <BsBagX fontSize="3rem" style={{margin:'auto', color:"white"}} />
                                    </Box>
                                </Box>
                                {cartList.map((item, index) => (
                                    <Box key={index} sx={{display:'flex', flexDirection:'row', marginLeft:'30px'}}>
                                        <Box sx={{width:'30%'}}>
                                            <AspectRatio ratio={1}>
                                                <img src={"/images/" + item.image_url_0} alt={item.name} style={{width:'100%'}}/>
                                            </AspectRatio>
                                        </Box>
                                        <Box sx={{width:'70%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                            <Box padding="10px">
                                                <Typography variant="h6" textAlign="left" color="white" fontFamily="Poppins" fontWeight="400" sx={{
                                                    fontSize: "1.5vw",
                                                }}>
                                                    {item.name}
                                                </Typography>
                                                <Typography variant="h6" textAlign="left" color="white" fontFamily="Poppins" fontWeight="400" sx={{
                                                    fontSize: "1.25vw",
                                                }}>
                                                    {item.secondary_name !== "" ? `[${item.secondary_name}]` : null}
                                                </Typography>
                                                <Typography variant="h6" textAlign="left" color="white" fontFamily="Poppins" fontWeight="500" sx={{
                                                    fontSize: "1vw",
                                                }}>
                                                    {item.quantity} X £{item.price}
                                                </Typography>
                                                <Typography variant="h6" textAlign="left" color="white" fontFamily="Poppins" fontWeight="500" sx={{
                                                    fontSize: "1vw",
                                                }}>
                                                    Size: {item.size}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IconButton color="inherit" aria-label="shopping cart" onClick={() => {handleRemoveFromCart({"id":item.id, "size":item.size})}}>
                                                    <TiDeleteOutline style={{ color:'white', marginRight:'20px'}} />
                                                </IconButton>
                                            </Box>
                                            
                                        </Box>
                                    </Box>
                                ))}
                                <Box 
                                sx={{
                                    display:'flex', 
                                    flexDirection:'row',
                                    marginTop:'20px',
                                }}>
                                    {cartList.length === 0 && (
                                        <Typography variant="h6" textAlign="left" color="white" fontFamily="Poppins" fontWeight="400" sx={{
                                            fontSize: "1.5vw",
                                            paddingBottom:'30px',
                                            marginLeft:'30px', 

                                        }}>
                                            Your cart is empty
                                        </Typography>
                                    )}
                                    {cartList.length > 0 && (
                                        <Box display='flex' flexDirection="column" width="100%">
                                        <Box sx={{
                                            display:'flex', 
                                            flexDirection:'row',
                                            width:'100%', 
                                            paddingTop:'50px',
                                            borderBottom: '1px solid #CCCCCC',
                                        }}>
                                            <Typography variant="h6" textAlign="left" width='50%' color="white" fontFamily="Poppins" fontWeight="400" sx={{
                                                fontSize: "1.5vw",
                                                paddingBottom:'30px',
                                                marginLeft:'30px', 
                                            }}>
                                                Total
                                            </Typography>
                                            <Typography variant="h6" textAlign="right" width='50%' color="#E51E10" fontFamily="Poppins" fontWeight="400" sx={{
                                                fontSize: "1.5vw",
                                                paddingBottom:'30px',
                                                marginRight:'50px',
                                            }}>
                                                £{totalPrice}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" width='100%'>
                                            <Button variant="outlined" component={Link} to="/cart"
                                            sx={{
                                                color: 'white',
                                                borderColor: 'white',
                                                '&:hover': {
                                                    borderColor: 'white',
                                                },
                                                paddingX: '30px',
                                                textTransform: 'none',
                                                borderRadius: '50px',
                                                width:'shrink',
                                                margin:'auto',
                                                marginTop:'50px',
                                                marginBottom:'50px',
                                            }}>
                                                Cart
                                            </Button>
                                            <Button variant="outlined"
                                            onClick={() => {
                                                navigate('/checkout', { state: { cartList: cartList, totalPrice: totalPrice  }, replace: true});
                                                // Navigate to Checkout
                                            }}
                                            sx={{
                                                color: 'white',
                                                borderColor: 'white',
                                                '&:hover': {
                                                    borderColor: 'white',
                                                },
                                                paddingX: '30px',
                                                textTransform: 'none',
                                                borderRadius: '50px',
                                                width:'shrink',
                                                margin:'auto',
                                                marginTop:'50px',
                                                marginBottom:'50px',
                                            }}>
                                                Checkout
                                            </Button>

                                        </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Drawer>
                    </Grid>
                </Grid>
               
                
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
