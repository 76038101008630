import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import './App.css';
import BaseLayout from './components/BaseLayout';
import Home from './Home/Home';
import Cart from './Cart/Cart';
import About from './About/About';
import Shop from './Shop/Shop';
import Product from './Product/Product';
import UnderConstruction from './UnderConstruction/UnderConstruction';
import { addToCart, removeFromCart, getCart, clearCart } from './utils/cookies';
import Order from './Order/Order';
import Test from './components/test';
import Info from './Info/Info';
import Contact from './Contact/Contact';
import Checkout from './Checkout/Checkout';
import Staff from './Staff/Staff';

const ScrollToTop = () => {
	const { pathname } = useLocation();
  
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
  
	return null;
  }
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 960);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
};

function App() {
	const isMobile = useIsMobile();
	const theme = useTheme();
	const [cart, setCart] = useState(getCart());
	const [underConstruction, setUnderConstruction] = useState(1); // 0 under construction, 1 customer access, 2 staff access
	useEffect(() => {
		console.log('app.js useEffect called');
		window.fbq('track', 'PageView', { isMobile: isMobile });
	},[])
	const handleClearCart = () => {
		clearCart();
		setCart([]);
	}
	const handleAddToCart = (item) => {
    	const newCart = addToCart(item);
		setCart(newCart);
	};

	const handleRemoveFromCart = (item) => {
		const newCart = removeFromCart(item);
		setCart(newCart);
	};

	const testing = false;
	return (
	<>
		{testing ? (
			
			<Test />
		) : (
			<ThemeProvider theme={theme}>
				<Router>
					<ScrollToTop />
					<Routes>
						{underConstruction === 0 ? (
							<>
							<Route path="/*" element={<Navigate to="/coming_soon" />} />
							<Route path="/coming_soon" element={<UnderConstruction setUnderConstruction={setUnderConstruction} isMobile={isMobile} />}/> 
							</>
						) : (
						<Route element={<BaseLayout cart={cart} handleRemoveFromCart={handleRemoveFromCart} isMobile={isMobile}/>}>
							<Route path="/*" element={<Navigate to="/home" />} />
							<Route path="/home" element={<Home isMobile={isMobile}/>} />
							<Route path="/about" element={<About />} />
							<Route path="/shop" element={<Shop />} />
							<Route path="/product/:id" element={<Product cart={cart} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} isMobile={isMobile} />} />
							<Route path="/order/:id" element={<Order handleClearCart={handleClearCart} />} />
							<Route path="/cart" element={<Cart cart={cart} setCart={setCart} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}/>} />
							{/* <Route path="/details" element={<LogInPage />} /> */}
							<Route path="/info/:infoId"  element={<Info />} />
							<Route path="/info" element={<Navigate to={"/info/delivery"} />} />
							<Route path="/contact"  element={<Contact />} />
							<Route path="/checkout"  element={<Checkout isMobile={isMobile}/>} />
							{underConstruction === 2 && 
							<>
								<Route path="/coming_soon" element={<Navigate to="/staff" />} />
								<Route path="/staff" element={<Staff />} />
							</>
							}
							{/* <Route path="/checkout-redirect"  element={<Navigate to={`/order/${orderCode}`} />} /> */}
							{/* <Route path="/test"  element={<TestNavigate />} /> */}
						</Route>
						)}
					</Routes>
				</Router>
			</ThemeProvider>
		)}
	</>
		// <Test />
		
  );
}
export default App;
