import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import CustomTextField from '../components/CustomTextField';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { styled } from '@mui/system';

const UnderConstruction = ({ setUnderConstruction, isMobile }) => {
    const [value, setValue] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [anchor, setAnchor] = useState(null);

    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        setAnchor(anchor ? null : event.currentTarget);
        if (value === 'silver925')
            setUnderConstruction(1);
        else
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
    }

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const PopupBody = styled('div')(
        ({ theme }) => `
        width: max-content;
        padding: 12px 16px;
        margin: 8px;
        border-radius: 8px;
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        box-shadow: ${
          theme.palette.mode === 'dark'
            ? `0px 4px 8px rgb(0 0 0 / 0.7)`
            : `0px 4px 8px rgb(0 0 0 / 0.1)`
        };
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        z-index: 1;
    `,);

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };


    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // This makes the box take up the full height of the viewport
                width: '100vw', // This makes the box take up the full width of the viewport
                backgroundColor: 'black',
                position: 'relative',
            }}
        >
            {isMobile ? (
                <>
                    <Box component="video" autoPlay muted loop preload="auto"
                        key="mobile-video"
                        sx={{ 
                            height: '100%', 
                            // minHeight: '740px', 
                            // height:'90vh',
                        objectFit: 'cover',
                        margin: '0 0 -4px 0',
                    }}>
                        <source src="/videos/ARG_Mobile1.mp4" type="video/mp4" />
                    </Box>
                    <form onSubmit={handlePasswordSubmit} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'absolute',
                            top: '50%',
                    }}>
                        <CustomTextField placeholder="cracked the code?" value={value} handleChange={handleChange} required sx={{
                            margin:'0',
                            borderRadius: '0px',
                            padding: '0 20px',
                            '&:focus-visible': { 
                                outline: 'none', 
                                border: '1px solid white',
                            },
                        }} />
                        <Button type="submit" variant="contained" sx={{
                            borderRadius: '0px',
                            padding: '0 20px',
                            width: '30%',
                            height: '62.222px',
                            color: 'black',
                            margin: '0 auto',
                            textDecoration: 'none',
                            fontSize: '1.2rem',
                            backgroundColor: 'white',
                            '&:hover': {
                                textDecoration: 'none',
                                backgroundColor: 'white',
                            },
                        }}>enter</Button>
                        <BasePopup  open={showPopup} anchor={anchor}>
                            <PopupBody>password incorrect</PopupBody>
                        </BasePopup>
                    </form> 
                </>         
            ) : (
                <>
                    <Box component="video" autoPlay muted loop preload="auto"
                        key="desktop-video"
                        sx={{ 
                            width: {
                                md: '900px',  // 960px - 1280px
                                lg: '1200px',  // 1280px - 1920px
                                xl: '1700px',  // 1920px and up
                            },
                            // minHeight: '740px', 
                            // height:'90vh',
                        objectFit: 'cover',
                        margin: '0 0 -4px 0',
                    }}>
                        <source src="/videos/ARG_Desktop1.mp4" type="video/mp4" />
                    </Box>
                    <Box component="form" onSubmit={handlePasswordSubmit} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        top: {
                            md: '53%',  // 960px - 1280px
                            lg: '56%',  // 1280px - 1920px
                            xl: '60%',  // 1920px and up
                        },
                    }}>
                        <CustomTextField placeholder="cracked the code?" value={value} handleChange={handleChange} required sx={{
                            margin:'0',
                            borderRadius: '0px',
                            padding: '0 20px',
                            '&:focus-visible': { 
                                outline: 'none', 
                                border: '1px solid white',
                            },
                        }} />
                        <Button type="submit" variant="contained" sx={{
                            borderRadius: '0px',
                            padding: '0 20px',
                            width: '30%',
                            height: '62.222px',
                            color: 'black',
                            margin: '0 auto',
                            textDecoration: 'none',
                            fontSize: '1.2rem',
                            backgroundColor: 'white',
                            '&:hover': {
                                textDecoration: 'none',
                                backgroundColor: 'white',
                            },
                        }}>enter</Button>
                        <BasePopup  open={showPopup} anchor={anchor}>
                            <PopupBody>password incorrect</PopupBody>
                        </BasePopup>
                    </Box>  
                </>
            )}
        </Box>
    );
};

export default UnderConstruction;