import { SvgIcon } from '@mui/material';

function RingIcon({ sx }) {
    const colour = sx.color;
    return (
        <SvgIcon sx={sx}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="800.000000pt" height="800.000000pt" viewBox="0 0 800.000000 800.000000"
            preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
                fill={colour} stroke="none">
                <path d="M3983 5999 c-246 -36 -499 -149 -648 -290 -90 -86 -239 -293 -305
                -424 -13 -27 -30 -35 -157 -76 -223 -73 -370 -135 -623 -261 -856 -428 -1518
                -1041 -1637 -1515 -24 -96 -24 -320 1 -428 24 -104 69 -232 113 -320 87 -171
                248 -367 393 -476 87 -66 240 -144 331 -168 238 -65 631 -69 989 -10 389 64
                730 182 1075 374 72 40 222 121 335 180 113 59 231 123 263 142 l57 35 93 -60
                c363 -235 747 -422 1139 -557 415 -143 739 -179 1000 -111 493 127 822 475
                970 1025 29 105 31 130 32 271 0 92 -5 179 -12 215 -115 532 -523 1099 -1147
                1595 -276 219 -502 365 -785 509 -386 196 -733 311 -1050 351 -99 12 -338 11
                -427 -1z m367 -94 c683 -80 1611 -573 2205 -1169 277 -278 557 -669 676 -946
                114 -264 128 -524 42 -763 -83 -231 -272 -529 -426 -673 -173 -162 -434 -254
                -719 -254 -337 0 -799 157 -1370 467 -100 55 -228 124 -283 153 -77 41 -219
                120 -243 136 -2 1 76 82 173 180 176 178 252 273 367 462 28 45 56 82 62 82 7
                0 127 -63 267 -141 270 -150 702 -365 834 -417 174 -68 413 -122 540 -122 79
                0 152 22 169 49 10 15 26 14 26 -3 0 -23 -40 -66 -83 -88 -37 -19 -53 -20
                -152 -15 -149 7 -306 43 -473 109 -72 28 -107 37 -114 30 -15 -15 35 -44 157
                -91 176 -68 301 -95 450 -95 108 -1 134 2 155 17 29 20 57 22 62 5 5 -14 -31
                -158 -55 -224 -44 -120 -121 -222 -202 -269 -36 -21 -137 -55 -252 -85 -64
                -16 -58 -40 10 -40 60 0 99 9 220 52 145 51 228 146 296 340 68 196 77 354 27
                464 -24 53 -55 103 -222 359 -182 279 -700 735 -1174 1032 -200 126 -498 297
                -571 329 -129 54 -581 222 -675 249 -48 14 -117 30 -153 35 -36 5 -117 17
                -179 26 -157 23 -232 16 -360 -35 -103 -40 -198 -91 -260 -138 -37 -28 -82
                -111 -107 -198 -26 -88 -17 -262 20 -375 33 -102 130 -311 182 -389 127 -196
                199 -279 220 -257 3 3 -33 63 -80 134 -47 71 -104 169 -125 218 -22 49 -61
                135 -86 192 -69 154 -116 332 -88 332 5 0 16 -28 26 -62 46 -169 281 -621 349
                -672 46 -34 42 -10 -13 74 -28 45 -78 132 -110 193 -58 112 -77 155 -145 339
                -50 136 -72 320 -44 369 13 22 15 13 20 -91 5 -102 10 -130 52 -254 46 -136
                60 -166 78 -166 15 0 10 36 -12 92 -56 142 -100 446 -69 483 28 34 40 18 40
                -54 0 -152 41 -299 142 -513 87 -185 151 -288 178 -288 19 0 10 32 -29 98 -53
                92 -152 293 -184 374 -37 91 -55 184 -63 320 -6 109 -6 116 13 127 20 10 21 7
                26 -87 6 -119 47 -263 117 -409 79 -167 215 -363 252 -363 22 0 0 45 -71 147
                -113 161 -174 289 -216 455 -35 139 -46 256 -25 288 7 10 10 -6 10 -55 0 -39
                9 -102 20 -142 29 -106 240 -494 317 -582 25 -28 34 -33 44 -24 10 10 4 24
                -28 70 -75 109 -206 336 -240 417 -43 101 -62 182 -65 276 -3 64 -1 75 15 78
                15 3 17 -5 17 -55 0 -122 57 -292 123 -368 31 -35 36 -38 43 -22 4 12 -1 28
                -13 46 -40 53 -103 209 -103 254 0 6 75 -101 168 -238 117 -175 197 -282 269
                -360 l102 -112 -47 -54 c-58 -68 -183 -190 -242 -237 -25 -20 -68 -58 -97 -84
                -28 -26 -104 -82 -169 -124 l-117 -76 -19 23 c-183 230 -272 392 -357 647 -81
                244 -90 418 -33 642 36 143 65 216 143 361 81 151 147 245 242 346 179 188
                366 287 602 316 76 10 282 6 395 -8z m-1398 -824 c-2 -13 -17 -57 -33 -98 -62
                -154 -85 -404 -54 -593 9 -58 18 -114 19 -125 1 -15 -29 -35 -141 -96 -241
                -130 -624 -373 -932 -592 -137 -97 -217 -163 -302 -247 -192 -191 -269 -299
                -269 -377 0 -25 -5 -43 -11 -43 -6 0 -41 14 -78 31 -119 57 -286 251 -317 369
                -10 40 -10 61 3 122 8 40 23 87 34 104 23 37 24 54 5 54 -23 0 -74 -67 -86
                -112 -6 -24 -15 -58 -20 -77 -24 -89 50 -238 188 -378 110 -112 168 -147 306
                -187 182 -53 305 -46 636 35 452 111 763 238 1320 540 144 78 387 241 563 377
                70 54 331 335 388 417 26 38 65 93 87 120 21 28 54 88 73 135 34 85 34 85 33
                243 -1 156 -1 157 20 151 38 -11 41 -23 40 -147 0 -115 -2 -128 -38 -227 -52
                -145 -113 -258 -201 -374 -77 -102 -89 -124 -57 -107 40 21 160 179 214 281
                19 36 46 83 60 105 34 52 84 212 93 298 4 37 11 67 14 67 4 0 13 -11 21 -24
                10 -20 10 -40 1 -103 -24 -167 -109 -354 -230 -507 -60 -76 -76 -105 -63 -118
                10 -10 72 55 147 155 74 98 171 316 190 425 4 23 11 40 16 36 13 -8 11 -92 -5
                -149 -28 -102 -106 -276 -160 -360 -31 -47 -56 -90 -56 -95 0 -28 44 8 88 72
                61 90 165 326 178 406 4 23 10 42 13 42 4 0 15 -16 24 -35 16 -33 16 -41 1
                -107 -23 -102 -60 -204 -101 -276 -19 -35 -32 -66 -29 -69 22 -23 108 133 146
                265 13 45 27 82 31 82 18 0 16 -38 -6 -123 -34 -129 -62 -192 -154 -347 -50
                -83 -76 -137 -69 -139 17 -6 65 53 137 169 46 73 75 136 105 226 23 69 45 122
                49 118 12 -13 7 -129 -8 -180 -23 -79 -148 -289 -250 -419 -48 -62 -53 -75
                -31 -75 27 0 161 179 250 333 31 54 47 57 22 5 -8 -18 -23 -53 -31 -78 -9 -25
                -27 -61 -40 -81 -30 -46 -78 -159 -71 -167 11 -10 48 33 77 90 16 31 33 59 39
                63 5 3 17 29 25 58 9 29 24 68 35 87 l19 35 1 -37 c0 -45 -25 -115 -85 -231
                -25 -49 -45 -94 -45 -100 -1 -7 -32 -55 -69 -107 -38 -52 -67 -97 -65 -99 19
                -20 140 131 199 249 82 164 98 225 103 400 4 155 4 155 -29 245 -39 109 -137
                302 -207 411 -48 74 -53 97 -18 77 17 -9 98 -131 168 -253 139 -239 172 -422
                114 -641 -45 -174 -64 -219 -140 -337 -116 -180 -264 -346 -481 -542 -202
                -181 -398 -305 -788 -495 -470 -230 -759 -328 -1137 -386 -124 -19 -186 -23
                -425 -23 -251 0 -290 2 -378 21 -166 36 -284 103 -426 238 -384 367 -549 916
                -370 1234 93 164 369 467 609 667 375 313 872 608 1326 787 96 38 212 67 254
                64 20 -2 24 -7 22 -26z m784 -69 c22 -12 -135 -42 -158 -30 -10 5 -18 18 -18
                30 0 20 3 21 80 15 45 -4 88 -10 96 -15z m228 -41 c5 -7 -303 -91 -349 -95
                -12 0 -20 5 -20 14 0 16 63 34 240 68 126 24 123 24 129 13z m313 -81 c46 -17
                49 -37 7 -45 -42 -7 -44 -38 -4 -43 30 -5 50 -20 50 -38 0 -9 -8 -12 -28 -8
                -19 4 -32 1 -41 -10 -17 -20 -8 -32 29 -39 23 -5 30 -12 30 -29 0 -25 -25
                -136 -32 -143 -2 -2 -22 2 -43 9 -48 16 -295 6 -362 -15 -38 -12 -44 -11 -63
                6 -11 10 -20 21 -20 25 0 18 172 40 308 40 134 0 142 1 142 20 0 14 -9 21 -37
                26 -48 9 -222 -1 -352 -21 -98 -15 -104 -14 -117 2 -7 10 -14 21 -14 25 0 10
                116 26 304 43 93 8 174 19 179 24 16 16 0 41 -26 41 -58 0 -341 -31 -406 -45
                -72 -15 -101 -12 -101 10 0 8 39 20 108 34 218 45 290 62 308 76 65 50 -149
                33 -344 -27 -88 -27 -105 -30 -113 -17 -5 8 -9 18 -9 22 0 20 169 66 318 86
                85 12 287 7 329 -9z m642 -281 c224 -127 591 -377 831 -568 325 -258 648 -604
                809 -868 77 -125 -7 -186 -233 -170 -181 13 -511 152 -1031 433 -197 107 -264
                148 -314 195 l-64 59 26 88 c38 126 50 212 44 321 -6 118 -36 206 -146 424
                -42 84 -78 156 -80 161 -6 14 23 1 158 -75z m-745 -159 c15 -5 26 -17 26 -28
                0 -17 -93 -154 -108 -160 -13 -4 -52 37 -87 92 -18 28 -42 60 -54 70 -11 11
                -21 23 -21 28 0 11 213 10 244 -2z m-1205 -395 c46 -102 169 -319 232 -411 27
                -39 49 -73 49 -76 0 -9 -218 -156 -320 -216 -109 -64 -301 -160 -416 -208
                -519 -215 -1115 -321 -1167 -207 -9 21 -7 34 18 81 35 69 234 272 375 384 235
                187 542 399 740 513 146 84 429 226 444 222 5 -2 25 -39 45 -82z"/>
                <path d="M3963 5828 c-48 -23 -21 -51 35 -36 22 6 32 14 32 28 0 23 -30 26
                -67 8z"/>
                <path d="M4124 5829 c-10 -17 10 -30 52 -35 45 -6 70 7 61 30 -7 19 -101 23
                -113 5z"/>
                <path d="M4340 5810 c0 -25 123 -46 140 -25 9 10 7 16 -9 24 -25 14 -131 14
                -131 1z"/>
                <path d="M4566 5771 c-4 -5 72 -35 166 -67 197 -65 280 -96 393 -149 75 -36
                106 -38 107 -10 0 12 -200 106 -352 166 -140 55 -299 85 -314 60z"/>
                <path d="M4347 5724 c-39 -12 -7 -29 67 -38 77 -9 102 -3 88 21 -8 13 -125 25
                -155 17z"/>
                <path d="M4633 5673 c-26 -9 -12 -32 30 -47 219 -81 227 -82 227 -47 0 11 -14
                24 -37 35 -52 23 -205 64 -220 59z"/>
                <path d="M4946 5551 c-10 -15 0 -29 35 -46 31 -17 49 -13 49 9 0 28 -71 58
                -84 37z"/>
                <path d="M4610 5537 c8 -7 74 -37 145 -67 72 -29 201 -89 287 -132 118 -60
                161 -77 174 -70 14 8 13 12 -12 36 -68 63 -496 246 -576 246 -27 0 -30 -2 -18
                -13z"/>
                <path d="M4482 5479 c6 -20 218 -103 231 -90 5 5 7 16 4 24 -13 35 -246 100
                -235 66z"/>
                <path d="M5337 5483 c-4 -3 -7 -10 -7 -14 0 -13 143 -90 157 -84 28 10 7 38
                -52 71 -59 33 -85 40 -98 27z"/>
                <path d="M5090 5467 c0 -14 40 -37 66 -37 20 0 30 30 13 41 -22 14 -79 11 -79
                -4z"/>
                <path d="M5230 5396 c0 -17 156 -116 182 -116 11 0 18 7 18 18 0 13 -26 33
                -82 65 -86 49 -118 57 -118 33z"/>
                <path d="M4826 5362 c-15 -14 -2 -33 35 -53 36 -18 44 -19 56 -7 12 12 11 17
                -5 31 -21 20 -77 38 -86 29z"/>
                <path d="M4486 5350 c6 -6 69 -32 140 -59 72 -27 165 -67 207 -91 76 -41 107
                -46 107 -16 0 17 -96 73 -190 110 -119 47 -291 83 -264 56z"/>
                <path d="M5600 5327 c0 -8 10 -23 23 -34 41 -38 190 -133 209 -133 52 0 -13
                64 -146 144 -64 38 -86 44 -86 23z"/>
                <path d="M5000 5257 c0 -21 53 -58 87 -60 60 -4 12 56 -54 69 -26 5 -33 3 -33
                -9z"/>
                <path d="M5301 5236 c-9 -10 -8 -16 3 -25 10 -9 16 -8 25 3 9 10 8 16 -3 25
                -10 9 -16 8 -25 -3z"/>
                <path d="M4490 5227 c0 -17 64 -47 105 -50 24 -2 30 2 30 18 0 14 -11 23 -40
                31 -55 17 -95 17 -95 1z"/>
                <path d="M5920 5121 c0 -12 24 -36 64 -65 68 -49 100 -58 94 -29 -3 17 -131
                113 -150 113 -4 0 -8 -9 -8 -19z"/>
                <path d="M4392 5008 c5 -15 237 -100 250 -92 15 10 8 33 -14 45 -30 16 -187
                59 -216 59 -15 0 -22 -5 -20 -12z"/>
                <path d="M6147 4938 c11 -18 64 -67 118 -108 164 -122 322 -292 540 -580 114
                -151 231 -333 292 -456 47 -94 57 -125 61 -177 l3 -62 -59 120 c-88 179 -224
                386 -350 535 -80 94 -246 260 -260 260 -25 0 -10 -25 61 -104 227 -251 384
                -483 535 -794 78 -160 115 -265 110 -319 l-3 -38 -18 55 c-50 146 -153 341
                -285 539 -105 157 -191 259 -210 247 -21 -13 -13 -30 44 -95 174 -200 405
                -648 421 -816 10 -107 -8 -112 -23 -6 -20 136 -89 269 -245 471 -51 65 -99
                112 -99 95 0 -12 35 -73 80 -140 67 -100 133 -219 164 -298 36 -91 62 -300 45
                -370 -6 -26 -15 -49 -19 -52 -4 -3 -10 59 -12 138 -4 120 -9 152 -30 208 -24
                64 -101 198 -199 345 -80 120 -179 217 -179 175 0 -5 56 -92 124 -194 193
                -285 225 -361 233 -548 7 -147 -9 -268 -44 -327 -13 -24 -27 -42 -29 -40 -2 3
                4 40 13 84 9 46 16 133 17 209 1 111 -2 140 -22 200 -57 174 -169 356 -337
                547 -84 96 -130 136 -141 125 -9 -9 8 -32 97 -133 123 -139 201 -252 273 -395
                83 -165 101 -239 92 -373 -11 -149 -42 -285 -73 -316 -18 -17 -16 -1 12 155
                41 221 -29 478 -193 710 -122 172 -552 599 -816 811 -273 220 -677 463 -701
                424 -3 -5 23 -25 57 -46 147 -88 597 -392 691 -466 141 -113 486 -460 589
                -594 294 -382 371 -577 324 -814 -26 -129 -65 -260 -92 -306 -27 -49 -118
                -134 -180 -170 -45 -26 -58 -54 -26 -54 27 0 116 45 207 104 61 40 104 79 162
                146 135 160 162 200 264 405 100 200 100 201 120 330 23 145 21 286 -6 409
                -25 117 -220 484 -348 656 -69 92 -262 313 -344 395 -187 186 -361 325 -406
                325 -19 0 -19 -1 0 -32z"/>
                <path d="M4760 4876 c0 -18 76 -60 96 -52 34 13 -5 50 -64 62 -25 5 -32 3 -32
                -10z"/>
                <path d="M6315 4650 c-9 -15 9 -42 59 -88 83 -77 88 -43 9 56 -34 41 -56 52
                -68 32z"/>
                <path d="M3676 4472 c-21 -34 189 -332 234 -332 19 0 9 22 -34 75 -24 29 -74
                101 -112 159 -68 103 -78 114 -88 98z"/>
                <path d="M6460 4310 c0 -5 21 -35 46 -67 93 -116 124 -140 124 -96 0 16 -81
                119 -123 156 -21 18 -47 23 -47 7z"/>
                <path d="M3336 4181 c-7 -11 94 -218 136 -278 28 -42 50 -53 63 -33 3 6 -13
                43 -38 83 -24 39 -65 109 -92 155 -48 80 -58 91 -69 73z"/>
                <path d="M3784 4129 c-10 -17 23 -46 38 -31 8 8 8 15 -2 27 -15 18 -26 19 -36
                4z"/>
                <path d="M3735 4050 c-9 -15 3 -47 21 -54 20 -8 28 17 14 43 -12 23 -25 27
                -35 11z"/>
                <path d="M3603 3964 c-5 -15 32 -64 49 -64 13 0 9 34 -8 58 -18 26 -32 28 -41
                6z"/>
                <path d="M6320 3877 c0 -16 75 -79 84 -70 10 11 7 21 -20 52 -26 32 -64 42
                -64 18z"/>
                <path d="M6620 3870 c0 -24 86 -120 106 -120 27 0 14 48 -27 94 -43 49 -79 61
                -79 26z"/>
                <path d="M6490 3848 c0 -20 61 -77 76 -71 20 7 18 17 -12 52 -26 31 -64 42
                -64 19z"/>
                <path d="M4844 3489 c-10 -16 21 -42 121 -100 216 -125 250 -138 250 -94 0 14
                -13 26 -40 39 -22 10 -67 36 -100 57 -53 33 -133 73 -202 100 -14 5 -25 4 -29
                -2z"/>
                <path d="M4770 3396 c0 -29 151 -123 280 -174 41 -17 134 -59 206 -95 124 -61
                233 -107 254 -107 6 0 10 6 10 13 0 6 -110 66 -243 131 -133 66 -295 148 -359
                183 -120 64 -148 74 -148 49z"/>
                <path d="M4700 3322 c0 -23 109 -91 283 -179 162 -80 199 -95 209 -84 11 11
                10 15 -2 22 -129 80 -454 249 -477 249 -7 0 -13 -4 -13 -8z"/>
                <path d="M4664 3229 c-10 -17 65 -86 120 -109 28 -12 115 -51 193 -86 144 -65
                183 -76 183 -49 0 10 -51 40 -137 81 -76 37 -186 91 -245 120 -69 34 -109 50
                -114 43z"/>
                <path d="M5363 3205 c65 -58 299 -174 350 -175 43 0 1 28 -164 111 -179 90
                -240 111 -186 64z"/>
                <path d="M4600 3145 c0 -24 147 -114 188 -115 33 0 4 32 -72 78 -87 54 -116
                63 -116 37z"/>
                <path d="M4520 3056 c0 -38 158 -130 363 -210 67 -27 87 -27 87 -1 0 13 -410
                225 -436 225 -8 0 -14 -6 -14 -14z"/>
                <path d="M5267 3033 c-25 -24 22 -55 49 -32 11 9 12 15 3 25 -12 15 -41 19
                -52 7z"/>
                <path d="M4875 3011 c-12 -20 91 -71 115 -56 16 10 -15 42 -48 50 -15 3 -34 8
                -44 11 -9 3 -19 0 -23 -5z"/>
                <path d="M5574 2998 c-8 -13 22 -38 47 -38 25 0 32 28 9 40 -25 13 -47 13 -56
                -2z"/>
                <path d="M4427 2983 c-15 -14 -3 -26 86 -84 103 -68 378 -209 409 -209 13 0
                18 6 16 18 -2 11 -60 46 -161 98 -86 45 -185 100 -220 122 -51 33 -107 62
                -121 62 -1 0 -6 -3 -9 -7z"/>
                <path d="M4340 2897 c0 -39 154 -134 490 -301 297 -148 422 -198 443 -177 16
                16 -12 34 -97 61 -155 49 -377 166 -750 394 -63 38 -86 44 -86 23z"/>
                <path d="M5050 2790 c0 -27 53 -55 69 -36 9 11 6 18 -18 35 -36 26 -51 27 -51
                1z"/>
                <path d="M6212 2739 c7 -22 49 -39 100 -39 46 0 69 13 51 31 -4 4 -42 12 -82
                15 -61 6 -73 5 -69 -7z"/>
                <path d="M5020 2670 c0 -23 41 -53 67 -48 25 5 29 39 7 55 -10 7 -30 13 -46
                13 -21 0 -28 -5 -28 -20z"/>
                <path d="M5884 2256 c-24 -19 -11 -34 41 -46 45 -10 65 -4 65 21 0 36 -69 52
                -106 25z"/>
                <path d="M2690 4985 c-271 -109 -626 -301 -914 -493 -208 -139 -495 -373 -524
                -429 -19 -34 3 -37 40 -5 92 81 427 334 537 407 263 173 569 339 831 450 168
                71 230 102 230 115 0 22 -73 6 -200 -45z"/>
                <path d="M2710 4897 c-131 -58 -484 -238 -567 -288 -78 -48 -153 -106 -153
                -120 0 -15 30 -10 58 10 129 91 518 298 720 383 39 17 72 37 72 44 0 22 -29
                16 -130 -29z"/>
                <path d="M2760 4831 c-63 -25 -131 -59 -154 -76 -35 -26 -7 -29 52 -5 131 54
                162 70 162 85 0 19 -4 18 -60 -4z"/>
                <path d="M2765 4747 c-38 -13 -185 -90 -248 -130 -34 -21 -45 -33 -38 -40 8
                -8 57 13 163 67 84 43 157 82 163 87 17 17 -9 27 -40 16z"/>
                <path d="M2423 4671 c-79 -42 -103 -59 -103 -72 0 -16 22 -10 116 35 60 28 94
                50 94 60 0 25 -29 19 -107 -23z"/>
                <path d="M2647 4562 c-136 -71 -197 -109 -197 -123 0 -19 50 -1 200 76 83 42
                146 80 148 89 6 29 -35 18 -151 -42z"/>
                <path d="M2153 4529 c-63 -41 -77 -59 -46 -59 24 0 144 72 140 85 -7 20 -33
                13 -94 -26z"/>
                <path d="M2368 4539 c-20 -11 -24 -39 -7 -39 20 0 49 21 49 35 0 17 -17 19
                -42 4z"/>
                <path d="M2593 4416 c-195 -101 -333 -189 -333 -213 0 -13 5 -12 28 3 77 51
                214 125 360 195 105 51 162 84 162 94 0 8 -8 15 -17 15 -10 0 -100 -43 -200
                -94z"/>
                <path d="M1854 4422 c-96 -71 -100 -115 -4 -48 62 43 78 65 54 73 -6 2 -28 -9
                -50 -25z"/>
                <path d="M2675 4349 c-144 -73 -199 -109 -203 -133 -3 -15 0 -17 20 -11 41 13
                338 165 338 173 0 9 -21 32 -29 32 -4 0 -60 -28 -126 -61z"/>
                <path d="M2342 4384 c-42 -29 -19 -46 33 -22 29 13 34 38 7 38 -10 0 -28 -7
                -40 -16z"/>
                <path d="M1653 4276 c-15 -13 -24 -28 -20 -32 11 -12 61 18 65 39 5 24 -12 21
                -45 -7z"/>
                <path d="M2585 4182 c-273 -141 -445 -252 -445 -288 0 -20 10 -18 65 17 94 60
                301 176 440 248 77 40 146 79 154 86 14 14 7 35 -12 35 -7 0 -97 -45 -202 -98z"/>
                <path d="M2354 4164 c-58 -30 -194 -132 -194 -145 0 -21 37 -5 133 60 56 37
                108 75 115 84 23 27 -4 28 -54 1z"/>
                <path d="M2132 4128 c-15 -15 -16 -38 -1 -38 20 0 49 21 49 35 0 18 -31 20
                -48 3z"/>
                <path d="M4473 3973 c-27 -28 -58 -91 -49 -100 9 -9 86 73 86 93 0 22 -18 26
                -37 7z"/>
                <path d="M2023 3935 c-26 -24 -42 -44 -34 -45 23 0 121 61 121 76 0 27 -38 14
                -87 -31z"/>
                <path d="M4296 3944 c-31 -30 -38 -54 -20 -61 19 -7 64 37 64 63 0 19 -25 18
                -44 -2z"/>
                <path d="M1864 3722 c-344 -234 -526 -391 -633 -542 -59 -85 -86 -149 -77
                -185 8 -31 36 -34 36 -2 1 29 44 112 100 188 42 58 236 246 344 334 72 59 367
                260 435 297 33 18 42 48 14 47 -10 0 -108 -62 -219 -137z"/>
                <path d="M1866 3819 c-26 -21 -35 -49 -15 -49 19 0 69 41 69 56 0 20 -23 17
                -54 -7z"/>
                <path d="M975 3745 c-27 -26 -33 -55 -12 -55 16 0 67 52 67 68 0 20 -30 13
                -55 -13z"/>
                <path d="M4546 3549 c-29 -34 -34 -59 -12 -59 19 0 70 66 61 80 -10 17 -22 12
                -49 -21z"/>
                <path d="M3583 3520 c-234 -142 -610 -342 -876 -467 -186 -86 -250 -124 -235
                -138 3 -3 65 15 139 41 168 58 452 195 629 304 74 45 169 103 210 128 93 57
                147 98 161 124 15 28 8 30 -28 8z"/>
                <path d="M1425 3480 c-12 -4 -27 -15 -34 -24 -20 -24 12 -33 44 -12 38 25 30
                51 -10 36z"/>
                <path d="M1299 3394 c-88 -44 -208 -212 -212 -295 -3 -48 16 -45 37 7 24 59
                120 188 177 239 76 68 75 89 -2 49z"/>
                <path d="M3577 3402 c-20 -22 -22 -42 -4 -42 20 0 57 30 57 46 0 20 -34 18
                -53 -4z"/>
                <path d="M3459 3331 c-20 -18 -22 -22 -9 -30 17 -12 55 9 55 29 0 22 -21 22
                -46 1z"/>
                <path d="M1083 3278 c-31 -34 -65 -106 -61 -130 5 -38 23 -29 41 21 10 28 29
                63 42 79 14 17 25 35 25 41 0 18 -25 12 -47 -11z"/>
                <path d="M3270 3214 c-94 -58 -310 -170 -505 -262 -135 -64 -142 -90 -13 -47
                121 40 212 84 408 197 183 106 206 122 221 150 18 34 -9 25 -111 -38z"/>
                <path d="M3360 3165 c-33 -35 -15 -51 30 -26 19 10 35 25 35 32 0 25 -40 21
                -65 -6z"/>
                <path d="M3066 2985 c-113 -58 -258 -123 -333 -150 -178 -64 -194 -86 -33 -45
                198 50 617 255 595 291 -4 5 -12 9 -18 8 -7 0 -102 -47 -211 -104z"/>
                <path d="M3416 3064 c-27 -19 -35 -29 -27 -37 14 -14 60 1 75 25 20 33 -7 40
                -48 12z"/>
                <path d="M3117 2906 c-196 -102 -238 -128 -220 -139 24 -15 370 148 422 198
                21 20 22 46 3 44 -4 0 -96 -47 -205 -103z"/>
                <path d="M2310 2886 c-107 -35 -138 -56 -78 -56 46 1 203 55 203 71 0 21 -16
                19 -125 -15z"/>
                <path d="M2523 2860 c-48 -21 -56 -40 -18 -40 34 0 87 28 83 42 -7 19 -21 19
                -65 -2z"/>
                <path d="M3168 2798 c-98 -52 -122 -78 -70 -78 37 1 207 91 207 110 0 29 -41
                19 -137 -32z"/>
                <path d="M2322 2795 c-40 -17 -34 -35 12 -35 35 0 62 21 51 40 -8 13 -23 12
                -63 -5z"/>
                <path d="M1833 2768 c-58 -13 -83 -24 -83 -34 0 -10 7 -14 18 -11 9 3 40 8 67
                12 84 11 135 27 135 41 0 19 -37 17 -137 -8z"/>
                <path d="M2753 2739 c-36 -10 -50 -26 -36 -40 13 -13 88 14 88 31 0 18 -13 20
                -52 9z"/>
                <path d="M4167 4854 c-16 -16 -6 -34 19 -34 19 0 25 5 22 17 -3 19 -29 29 -41
                17z"/>
                </g>

            </svg>    
        </SvgIcon>
    );
}

export default RingIcon;