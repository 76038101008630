import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';
import NavBarMob from './NavBarMob';
const BaseLayout = ({ cart, handleRemoveFromCart, isMobile }) => {

    return (
        <div>
            {isMobile ? <NavBarMob cart={cart} /> : <NavBar cart={cart} handleRemoveFromCart={handleRemoveFromCart} /> }
            <main style={{ flexGrow: 1 }}>
                <Outlet />
            </main>
            <Footer />
        </div>
    );
};

export default BaseLayout;
