import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box, Grid } from '@mui/material';
import { HiOutlineTrophy } from "react-icons/hi2";
import { LiaShippingFastSolid } from "react-icons/lia";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const StyledLink = ({ to, text, sx, ...rest }) => {
    return (
        <Box component={Link} to={to} 
            sx={{ 
                fontFamily: 'HelveticaNeue',
                fontWeight:'900',
                color: 'white', 
                textDecoration: 'none', 
                "&:hover": {
                    textDecoration: 'underline',
                },
                ...sx,
            }}
            
            {...rest} // Forward other props
        >
            {text}
        </Box>
    );
}


const Footer = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const iconFontSize = isXs ? '15vw' : isSm ? '4rem' : isMd ? '4rem' : '3rem'; // Adjust as needed


    return (
        <footer style={{ backgroundColor:'#0B0A0A', marginTop: "auto"}}>
            <Grid container spacing={0} sx={{ borderTop: '1px solid white', overflow:'hidden' }}>
                <Grid container item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent:{xs:'flex-start', md:'center'}, marginY:'60px', flexDirection:'row', paddingX:{xs:'10%', md:'0'} }}>
                    <Grid item>
                        <HiOutlineTrophy color="white" fontSize={iconFontSize} />
                    </Grid>
                    <Grid container item sx={{ display: 'flex', alignItems:'flex-start', justifyContent:'center', flexDirection:"column", width:'auto', paddingLeft:"3%" }}>
                        <Grid item>
                            <Typography color="white" variant='h5' sx={{
                                fontSize:{xs:'5vw', md:'1.25vw'},
                                fontFamily: 'HelveticaNeue',
                                textTransform: 'uppercase',
                            }}>
                                Sterling Silver
                            </Typography>
                        </Grid>
                         <Grid item>
                            <Typography color="#E5E6E4" variant='h6' sx={{
                                fontSize:{xs:'3.5vw', md:'1vw'}
                            }}>
                                crafted out of 925 silver
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent:{xs:'flex-start', md:'center'}, marginBottom:'60px', marginTop:{xs:'0', md:'60px'}, flexDirection:'row', paddingX:{xs:'10%', md:'0'} }}>
                    <Grid item>
                        <VerifiedOutlinedIcon sx={{ color:"white", fontSize:iconFontSize }}/>
                    </Grid>
                    <Grid container item sx={{ display: 'flex', alignItems:'flex-start', justifyContent:'center', flexDirection:"column", width:'auto', paddingLeft:"3%" }}>
                        <Grid item>
                            <Typography color="white" variant='h5' sx={{
                                fontSize:{xs:'5vw', md:'1.25vw'},
                                fontFamily: 'HelveticaNeue',
                                textTransform: 'uppercase',
                            }}>
                                Hallmarked
                            </Typography>
                        </Grid>
                         <Grid item>
                            <Typography color="#E5E6E4" variant='h6' sx={{
                                fontSize:{xs:'3.5vw', md:'1vw'}
                            }}>
                                certified by the London Assay Office
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center', justifyContent:{xs:'flex-start', md:'center'}, marginBottom:'60px', marginTop:{xs:'0', md:'60px'}, flexDirection:{xs:'row', md:'row', lg:'row'}, paddingX:{xs:'10%', md:'0'} }}>
                    <Grid item>
                        <LiaShippingFastSolid color="white" fontSize={iconFontSize}/>
                    </Grid>
                    <Grid container item sx={{ display: 'flex', alignItems:'flex-start', justifyContent:'center', flexDirection:"column", width:'auto', paddingLeft:"3%" }}>
                        <Grid item>
                            <Typography color="white" variant='h5' sx={{
                                fontSize:{xs:'5vw', md:'1.25vw'},
                                fontFamily: 'HelveticaNeue',
                                textTransform: 'uppercase',
                            }}>
                                Free Shipping
                            </Typography>
                        </Grid>
                         <Grid item>
                            <Typography color="#E5E6E4" variant='h6' sx={{
                                fontSize:{xs:'3.5vw', md:'1vw'}
                            }}>
                                for all orders across the UK
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ borderTop: '1px solid white', borderBottom: '1px solid white', overflow:'hidden' }}>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', padding:'20px 20px 5px 20px', borderRight:'1px solid white', backgroundColor:'black', borderBottom:{xs:'1px solid white', sm:'none'} }}>
                    <img src="/videos/Untitled_video_-_Made_with_Clipchamp_1.gif" alt="logo" style={{ width:'100%', flexShrink: '0' }} />
                </Grid>
                <Grid item xs={12} sm={3} sx={{ display:{xs:'none', sm:'flex'}, alignItems: 'center', justifyContent:'center', padding:'5px 20px 5px 20px',  borderRight:'1px solid white' }}>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'center', sm:'start', md:'start'}}}>
                            <Box component={StyledLink} to="/home" text="Home"sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }} />
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'center', sm:'start', md:'start'}}}>
                            <Box component={StyledLink} to="/shop" text="Shop" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'center', sm:'start', md:'start'}}}>
                            <Box component={StyledLink} to="/about" text="About" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'center', sm:'start', md:'start'}}}>
                            <Box component={StyledLink} to="/contact" text="Contact" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', padding:'5px 20px 5px 20px',  borderRight:'1px solid white', borderBottom:{xs:'1px solid white', sm:'none'} }}>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'start', md:'start'}}}>
                            <Box component={StyledLink} to='/info/delivery' text="Delivery" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'start', md:'start'}}}>
                            <Box component={StyledLink} to='/info/returns' text="Returns" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'start', md:'start'}}}>
                            <Box component={StyledLink} to='/info/privacy' text="Privacy Policy" sx={{ color: 'white', textDecoration: 'none', textAlign:{xs:'center', sm:'start', md:'start'}, textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'start', md:'start'}}}>
                            <Box component={StyledLink} to='/info/ring-sizing' text="Ring Sizing" sx={{ color: 'white', textDecoration: 'none', textAlign:{xs:'center', sm:'start', md:'start'}, textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center',padding:'5px 20px 20px 20px', }}>
                    <Grid container spacing={2} direction="column" sx={{paddingY:'30px'}}>
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'start', md:'start'}}}>
                            <Box component={StyledLink} to="https://www.instagram.com/silver__riot/" text="Instagram" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                        <Grid item xs={12} sm={4} sx={{ width:'100%', display:'flex', justifyContent:{xs:'start', md:'start'}}}>
                            <Box component={StyledLink} to="https://www.tiktok.com/@silver__riot" text="TikTok" sx={{ color: 'white', textDecoration: 'none', textTransform: 'uppercase', fontSize:{xs:'10vw', sm:'4vw', md:'2vw'},  }}/>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
            <Box display="flex" alignItems="center"justifyContent={{xs:"left", sm:'center'}} margin="auto" paddingY="10px" paddingX="20px">
                <Typography variant="caption" color="white" height="100%" sx={{
                    fontSize:{xs:'3vw', sm:'3vw', md:'0.5vw'},
                    fontFamily: 'HelveticaNeue',
                    fontWeight:'900',
                
                }}>© 2024 SILVERRIOT | All Rights Reserved </Typography>
                {/*<Box padding="5px">
                    <FaCcVisa size="2em" color="white" />
                    <FaCcPaypal size="2em" color="white" />
                    <FaCcStripe size="2em" color="white" />
                    <FaCcMastercard size="2em" color="white" />
                </Box>*/}
            </Box>
        </footer>
    );
};

export default Footer;