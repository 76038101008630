import { SvgIcon } from '@mui/material';

function BraceletIcon({ sx }) {
    const colour = sx.color;
    return (
        <SvgIcon sx={sx}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="300.000000pt" height="300.000000pt" viewBox="0 0 300.000000 300.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
                fill={colour} stroke="none">
                <path d="M1500 2254 c-270 -37 -489 -128 -632 -262 -86 -81 -132 -150 -161
                -242 -15 -49 -27 -70 -38 -70 -43 0 -149 -114 -149 -159 0 -7 -18 -33 -39 -57
                -53 -60 -65 -117 -58 -255 5 -99 9 -117 47 -204 29 -65 53 -105 78 -127 68
                -59 225 -118 316 -118 19 0 50 -7 70 -15 24 -10 72 -16 148 -16 244 -2 283 0
                358 21 41 11 99 25 129 31 30 5 88 23 129 39 40 17 84 30 96 30 36 0 145 59
                169 92 12 17 38 38 58 49 20 10 51 33 69 51 18 18 51 44 74 59 23 14 64 52 92
                84 27 32 86 94 131 139 65 66 83 89 89 121 4 22 20 55 37 74 53 61 85 171 69
                231 -6 19 -17 73 -26 120 -10 55 -31 117 -60 178 -52 106 -74 122 -168 122
                -49 0 -54 2 -66 30 -10 21 -24 32 -50 39 -123 32 -522 40 -712 15z m366 -80
                c20 -36 35 -67 33 -69 -2 -2 -49 -8 -104 -15 -135 -15 -250 -36 -368 -67 l-99
                -25 -42 41 c-23 22 -44 41 -48 41 -3 0 -9 -21 -13 -46 -11 -77 -12 -78 -65
                -34 -26 22 -50 40 -53 40 -2 0 -8 -31 -11 -70 -4 -38 -9 -70 -12 -70 -2 0 -21
                18 -41 40 -42 48 -49 43 -59 -42 -4 -32 -10 -58 -15 -58 -5 0 -19 18 -31 40
                -13 22 -26 40 -30 40 -5 0 -9 -17 -9 -37 -1 -21 -4 -51 -8 -68 l-7 -30 -8 32
                c-12 50 -21 47 -40 -13 -9 -30 -21 -52 -27 -49 -5 4 -9 33 -9 67 l0 60 63 62
                c116 115 247 182 462 237 109 27 326 55 447 58 l57 1 37 -66z m193 -22 c17
                -27 31 -52 31 -56 0 -4 -15 -6 -32 -4 -32 3 -33 4 -36 56 -2 28 -1 52 1 52 3
                0 19 -22 36 -48z m-94 -3 c28 -43 24 -64 -11 -57 -15 2 -20 14 -22 51 -2 26
                -1 47 1 47 2 0 17 -19 32 -41z m235 -67 c0 -9 -7 -11 -22 -7 -32 10 -36 15
                -43 60 l-6 40 35 -40 c20 -22 36 -46 36 -53z m90 -24 c0 -12 -38 -10 -45 2 -3
                5 -8 33 -11 62 l-5 53 30 -55 c17 -30 30 -58 31 -62z m120 80 c34 -24 68 -73
                58 -83 -4 -4 -27 5 -52 20 -35 20 -52 25 -79 20 -30 -6 -36 -4 -42 15 -16 50
                56 68 115 28z m-10 -88 c21 -21 27 -64 11 -74 -13 -8 -61 51 -61 75 0 25 25
                24 50 -1z m-253 -50 c70 -10 81 -14 103 -42 46 -59 87 -94 121 -104 25 -7 35
                -17 41 -41 7 -26 2 -40 -27 -88 -19 -32 -37 -77 -41 -100 -5 -36 -12 -46 -44
                -64 -47 -26 -83 -68 -117 -135 -14 -28 -35 -57 -47 -65 -12 -8 -46 -40 -77
                -71 -31 -32 -79 -70 -107 -84 -28 -14 -62 -41 -76 -59 -16 -21 -41 -38 -65
                -45 -21 -7 -55 -23 -75 -37 -23 -16 -51 -25 -75 -25 -44 0 -95 -16 -131 -40
                -32 -22 -157 -39 -238 -33 -45 4 -75 1 -106 -11 -40 -15 -48 -15 -106 0 -51
                13 -71 14 -101 5 -21 -6 -41 -16 -44 -21 -4 -6 -25 1 -50 16 -24 14 -73 30
                -111 36 -112 17 -181 85 -183 181 -1 28 32 151 47 173 4 6 21 18 38 27 32 16
                96 95 111 135 5 11 24 31 43 44 19 13 49 46 65 73 46 75 123 146 211 192 260
                137 744 222 1041 183z m193 -5 c7 -9 37 -30 67 -48 76 -45 69 -88 -10 -66 -47
                13 -97 60 -97 91 0 39 18 49 40 23z m172 -46 c33 -96 36 -239 5 -239 -11 0
                -47 214 -47 278 0 40 22 19 42 -39z m-59 -121 c3 -18 1 -37 -3 -41 -9 -11 -34
                49 -25 62 12 20 23 11 28 -21z m-1676 -71 c-5 -61 -25 -95 -47 -82 -16 10 -6
                83 15 125 24 46 38 27 32 -43z m1707 -3 c6 -15 -17 -43 -36 -44 -3 0 -14 -21
                -24 -47 -10 -27 -22 -54 -27 -60 -14 -19 -37 -16 -37 5 1 28 39 105 67 135 30
                31 48 34 57 11z m71 -106 c-9 -35 -27 -80 -42 -100 -25 -37 -92 -78 -127 -78
                -24 0 -13 15 49 60 50 37 88 95 120 185 16 43 16 -7 0 -67z m-46 5 c-14 -39
                -28 -53 -51 -53 -17 0 -19 4 -13 27 9 38 23 53 51 53 21 0 23 -2 13 -27z
                m-1770 -5 c11 -11 18 -25 15 -29 -3 -5 -42 -27 -87 -49 -118 -57 -131 -53 -74
                21 30 40 87 79 114 79 7 0 21 -10 32 -22z m141 -7 c0 -22 -54 -71 -93 -85
                -103 -36 -126 -27 -54 22 23 16 51 39 61 51 19 21 86 31 86 12z m1530 -77 c0
                -9 -31 -48 -69 -88 -38 -39 -81 -96 -96 -126 -50 -100 -82 -119 -71 -42 17
                127 77 212 177 250 30 12 55 21 57 21 1 1 2 -6 2 -15z m-1750 -44 c0 -5 -2
                -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-31 -23 c8
                -11 -4 -26 -54 -71 -44 -39 -72 -73 -86 -106 -33 -78 -45 -79 -39 -5 4 49 12
                74 36 107 50 71 117 106 143 75z m131 5 c0 -23 -55 -83 -93 -101 -43 -21 -97
                -28 -97 -13 0 4 16 18 35 30 19 13 46 39 60 58 21 27 33 34 61 34 18 0 34 -3
                34 -8z m1664 -102 c-26 -48 -79 -94 -96 -84 -31 20 54 134 100 134 l23 0 -27
                -50z m-74 32 c0 -13 -49 -56 -56 -50 -9 9 27 58 42 58 8 0 14 -4 14 -8z
                m-1809 -115 c-18 -18 -25 2 -10 30 14 26 14 26 17 4 2 -13 -1 -28 -7 -34z
                m1759 -11 c0 -87 -110 -199 -197 -200 -57 -1 -65 16 -16 33 44 16 139 103 147
                135 3 12 13 39 21 60 13 31 18 36 30 26 9 -7 15 -30 15 -54z m-1695 22 c-17
                -57 -49 -118 -71 -139 -23 -21 -24 -21 -24 -3 0 38 19 84 51 124 36 44 54 52
                44 18z m1545 -3 c0 -2 -7 -23 -16 -45 -17 -42 -55 -63 -76 -42 -8 8 0 23 29
                52 35 35 63 50 63 35z m10 -106 c0 -20 -24 -26 -35 -9 -8 13 14 42 26 34 5 -3
                9 -14 9 -25z m-1670 -66 c-1 -86 -10 -96 -24 -26 -7 28 8 113 19 113 3 0 5
                -39 5 -87z m1600 47 c19 -12 18 -12 -87 -64 -57 -28 -100 -57 -114 -76 -13
                -16 -26 -30 -30 -30 -12 0 11 82 31 110 38 54 155 89 200 60z m-1506 -101 c17
                -71 21 -145 7 -153 -13 -8 -56 216 -43 228 10 11 19 -8 36 -75z m1376 -88
                c-15 -55 -37 -81 -94 -107 -75 -35 -196 -30 -196 8 0 7 16 9 48 5 40 -6 55 -2
                107 26 51 27 62 38 76 76 9 24 24 50 35 58 16 12 19 11 26 -8 5 -12 4 -38 -2
                -58z m-127 50 c-5 -18 -61 -51 -88 -51 -28 0 -15 26 23 48 47 27 72 29 65 3z
                m247 10 c0 -5 -15 -22 -32 -37 -33 -27 -33 -27 -36 -5 -2 12 2 29 8 37 14 15
                60 20 60 5z m-1387 -96 c44 -42 64 -80 55 -106 -5 -15 -74 45 -92 80 -20 39
                -28 71 -18 71 4 0 29 -20 55 -45z m1000 12 c27 -30 16 -42 -40 -42 -40 -1 -63
                -8 -103 -33 -63 -39 -73 -40 -59 -4 15 39 48 72 94 93 46 21 82 16 108 -14z
                m147 8 c10 -12 8 -16 -15 -25 -14 -5 -31 -10 -36 -10 -13 0 -11 24 3 38 16 16
                33 15 48 -3z m-1019 -36 c47 -16 96 -54 115 -90 8 -17 14 -33 11 -35 -2 -3
                -26 14 -53 36 -27 22 -70 48 -96 58 -84 29 -61 59 23 31z m639 -29 c-25 -35
                -40 -38 -58 -10 -11 18 -10 22 10 30 13 5 33 9 46 9 l23 1 -21 -30z m-356 -10
                c50 -24 103 -66 95 -74 -3 -4 -33 6 -65 21 -43 20 -75 27 -121 28 -52 0 -63 3
                -63 17 0 9 6 19 13 21 30 13 104 6 141 -13z m264 -7 c24 -18 46 -81 25 -71 -4
                2 -37 18 -73 36 -38 19 -80 32 -102 32 -37 0 -50 13 -25 24 28 13 149 -2 175
                -21z m269 0 c6 -18 -37 -37 -51 -23 -8 8 -6 15 9 26 21 15 37 14 42 -3z m-792
                -34 c15 -15 -10 -31 -39 -23 -17 4 -26 13 -26 26 0 17 4 19 28 13 15 -3 31
                -11 37 -16z m-131 -23 c18 -7 57 -19 87 -25 48 -11 68 -23 69 -43 0 -14 -39
                -9 -103 13 -60 20 -97 43 -97 61 0 11 5 10 44 -6z m373 0 c11 -11 -5 -18 -50
                -23 -45 -5 -65 6 -51 28 7 11 89 7 101 -5z m257 -21 l33 -35 44 14 c24 7 61
                21 83 30 21 10 41 15 43 12 12 -11 -65 -75 -102 -85 -101 -28 -165 -3 -165 64
                0 46 22 46 64 0z m98 13 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11
                -7 -17z m265 6 c5 -14 -59 -41 -107 -46 -29 -2 -35 0 -35 17 0 11 11 25 25 32
                29 15 112 13 117 -3z m-455 -19 c-2 -14 -8 -25 -13 -25 -5 0 -9 11 -9 25 0 14
                6 25 14 25 9 0 12 -8 8 -25z m-324 -5 c49 -24 119 -26 168 -5 27 11 41 12 56
                4 19 -10 19 -11 2 -30 -25 -28 -116 -52 -165 -44 -41 7 -91 42 -110 76 -12 24
                -3 24 49 -1z m346 -60 c19 -18 -7 -32 -51 -27 -52 6 -60 11 -44 31 13 15 77
                12 95 -4z"/>
                </g>
            </svg>
        </SvgIcon>
    );
}

export default BraceletIcon;