import React from 'react';
import { Grid, Button, MenuItem, Stack, Paper, Popper, Grow, MenuList, ClickAwayListener } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import HeroImgHeader from '../components/HeroImgHeader';
import ShopCard from './ShopCard';
import { GiSettingsKnobs } from "react-icons/gi";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
// import TestColours from '../components/TestColours';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { get_products } from '../utils/common_requests';

const Shop = () => {
    const [products, setProducts] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // implement when i figure out loading skeletons
    const [filter, setFilter] = useState(null);
    const [sort, setSort] = useState(null);
    const location = useLocation();
    const [filterInitialized, setFilterInitialized] = useState(false); // New state to track filter initialization

    useEffect(() => {
        if (!filterInitialized) {
            if (location.state) {
                setFilter(location.state.filter);
                setFilterInitialized(true); // Mark filter as initialized
            } else {
                setFilterInitialized(true); // Mark filter as initialized
            }

        }
    }, [location.state, filterInitialized]);  
     


    useEffect(() => {
        const fetchProducts = async () => {
            const prod = await get_products();    
            setProducts(prod);
            if (prod !== undefined) {
                setIsLoading(false); 
                window.fbq('track', 'PageViewShop');
            }
        };
        fetchProducts();
    },[]);
    
    const sortProducts = (products, sort) => {
        if (sort === null) {
            return products;
        }
        if (sort === 'high2low') {
            return [...products].sort((a, b) => b.price - a.price);
        }
        if (sort === 'low2high') {
            return [...products].sort((a, b) => a.price - b.price);
        }
        if (sort === 'new2old') {
            return [...products].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }
        if (sort === 'old2new') {
            return [...products].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        }
        return products;
    };

    useEffect(() => {
        setIsLoading(true);
        setSortedProducts(sortProducts(products.filter((product) => filter === null || product.type == filter), sort));
        setIsLoading(false);
    }, [sort, products, filter])
    // filter menu
    const [openFilter, setOpenFilter] = useState(false);
    const anchorRefFilter = useRef(null);

    const handleToggleFilter = () => {
        setOpenFilter((prevOpenFilter) => !prevOpenFilter);
    };

    const handleCloseFilter = (event) => {
        if (anchorRefFilter.current && anchorRefFilter.current.contains(event?.target)) {
            return;
        }
        setFilter(event);
        setOpenFilter(false);
    };

    const prevOpenFilter = useRef(openFilter);
    useEffect(() => {
        if (prevOpenFilter.current === true && openFilter === false) {
        anchorRefFilter.current.focus();
        }

        prevOpenFilter.current = openFilter;
    }, [openFilter]);


    // sort menu
    const [openSort, setOpenSort] = useState(false);
    const anchorRefSort = useRef(null);

    const handleToggleSort = () => {
        setOpenSort((prevOpenSort) => !prevOpenSort);
    };

    const handleCloseSort = (event) => {
        if (anchorRefSort.current && anchorRefSort.current.contains(event?.target)) {
            return;
        }
        setSort(event);
        setOpenSort(false);
    };

    const prevOpenSort = useRef(openSort);
    useEffect(() => {
        if (prevOpenSort.current === true && openSort === false) {
        anchorRefSort.current.focus();
        }

        prevOpenSort.current = openSort;
    }, [openSort]);


    const isSmallScreen = useMediaQuery({ maxWidth: 450 });
    const [iconDisplay, setIconDisplay] = useState('inline-flex');
    useEffect(() => {
        setIconDisplay(isSmallScreen ? 'none' : 'inline-flex');
    },[isSmallScreen])
    return (
        <div>
            <HeroImgHeader titleA={'Shop'} titleB={'Shop'} />
            <Grid container padding={2} 
            sx={{
                backgroundColor: '#0B0A0A',
                height: {xs:"75px", sm:'200px'},
                borderTop: '1px solid white',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Grid item xs={6} sm={3} lg={3} sx={{ display:'flex', justifyContent:{xs:sort === null ? 'center' : 'flex-start', sm:'flex-start', lg:'center' } }}>
                    <Stack direction="row" spacing={2} sx={{width:{xs:'100px', sm:'150px', md:'250px'},}}>
                        <Button
                            ref={anchorRefFilter}
                            id="composition-button"
                            aria-controls={openFilter ? 'composition-menu' : undefined}
                            aria-expanded={openFilter ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggleFilter}
                            sx={{
                                textTransform: 'none',
                                color: 'white',
                                borderColor: 'white',
                                border: '1px solid white',
                                padding:'10px',
                                width:{xs:'100px', sm:'150px', md:'250px'},
                                fontSize:{md:'1.5vw', lg:'1.65rem'},
                                borderRadius:'0',
                                alignItems:'center'
                            }}
                        >
                            <GiSettingsKnobs style={{ paddingRight:'10px', fontSize:'3vw', display:iconDisplay }}/>{filter === null ? 'Filter' : filter === 'ring' ? 'Ring' : filter === 'chain' ? 'Chain' : filter === 'bracelet' ? 'Bracelet' : 'Error'}
                        </Button>
                        <Popper
                            open={openFilter}
                            anchorEl={anchorRefFilter.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                            sx={{ width:{xs:'100px', sm:'150px', md:'250px'}, zIndex: 2, borderRadius:'0', backgroundColor:'#0B0A0A' }}
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={() => handleCloseFilter(null)}>
                                        <MenuList
                                            autoFocusItem={openFilter}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            sx={{ padding: '0', borderRadius:'0', backgroundColor:'#0B0A0A' }}
                                        >
                                            <MenuItem onClick={() => handleCloseFilter(null)} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>None</MenuItem>
                                            {/* <MenuItem onClick={() => handleCloseFilter('chain')} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>Chain</MenuItem> */}
                                            <MenuItem onClick={() => handleCloseFilter('ring')} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>Ring</MenuItem>
                                            <MenuItem onClick={() => handleCloseFilter('bracelet')} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>Bracelet</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                        </Popper>
                    </Stack>
                </Grid>
                <Grid item xs={0} sm={6} lg={6} />
                <Grid item xs={6} sm={3} lg={3} sx={{ display:'flex', justifyContent:{xs:sort === null ? 'center' : 'flex-end', sm:'flex-end', lg:'center' }}}>
                    <Stack direction="row" spacing={2} sx={{width:{xs:sort === null ? '100px' : '200px', sm:sort === null ? '150px' : '200px', md:'250px'},}}>
                        <Button
                            ref={anchorRefSort}
                            id="composition-button"
                            aria-controls={openSort ? 'composition-menu' : undefined}
                            aria-expanded={openSort ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggleSort}
                            sx={{
                                textTransform: 'none',
                                color: 'white',
                                borderColor: 'white',
                                border: '1px solid white',
                                padding:'10px',
                                width:{xs:sort === null ? '150px' : '200px', sm:sort === null ? '150px' : '200px', md:'250px'},
                                fontSize:{md:'1.5vw', lg:sort === null ? '1.65rem' : '1.2rem'},
                                borderRadius:'0',
                                alignItems:'center'
                            }}
                        >
                            {sort === null ? 'Sort by' : sort === 'high2low' ? 'Price High to Low' : sort === 'low2high' ? 'Price Low to High' : sort === 'new2old' ? 'Newest to Oldest' : sort === 'old2new' ? 'Oldest to Newest' : 'Error'}<UnfoldMoreIcon sx={{ paddingRight:'10px', fontSize:'3vw', display:iconDisplay  }}/>
                        </Button>
                        <Popper
                            open={openSort}
                            anchorEl={anchorRefSort.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                            sx={{ width:{xs:sort === null ? '150px' : '200px', sm:sort === null ? '150px' : '200px', md:'250px'}, translate:{xs:sort === null ? '-25px' : '0', sm:sort === null ? '0' : '0'}, zIndex: 2, borderRadius:'0', backgroundColor:'#0B0A0A' }}
                        >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={() => handleCloseSort(null)}>
                                        <MenuList
                                            autoFocusItem={openSort}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            sx={{ padding: '0', borderRadius:'0', backgroundColor:'#0B0A0A' }}
                                        >
                                            <MenuItem onClick={() => handleCloseSort(null)} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>None</MenuItem>
                                            <MenuItem onClick={() => handleCloseSort('high2low')} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>Price High to Low</MenuItem>
                                            <MenuItem onClick={() => handleCloseSort('low2high')} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>Price Low to High</MenuItem>
                                            <MenuItem onClick={() => handleCloseSort('new2old')} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>Newest to Oldest</MenuItem>
                                            <MenuItem onClick={() => handleCloseSort('old2new')} sx={{ borderRadius:'0', border:'1px solid white', color:'white', justifyContent:'center', fontSize:{md:'1.5vw', lg:'1.5vw'}, }}>Oldest to Newest</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                        </Popper>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container width='calc(100% - 2px)'>
                
                {sortedProducts.length > 0 && sortedProducts.map((product) => (
                    <Grid item xs={12} sm={6} lg={4} key={product.id} sx={{height:{xs:'100vw', sm:"60vw", lg:'43vw'}}}>
                        <ShopCard product={product} />
                    </Grid>
                ))}
            </Grid>
        </div>		

        
    );
};

export default Shop;
