import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const useStyles = makeStyles({
  marquee: {
    
    overflow: 'visible',
    whiteSpace: 'nowrap',
    animation: '$marquee 20s linear infinite',
    color: 'black',
    fontFamily: 'HelveticaNeue',
    textTransform: 'uppercase',

  },
  '@keyframes marquee': {
    '0%': {
      transform: 'translateX(-25%)',
    },
    '100%': {
      transform: 'translateX(100%)',
    },
  },
});

const Marquee = ({ text }) => {
  const classes = useStyles();

  return (
    <Box sx={{backgroundColor:'#721e1d', overflowX:"hidden", paddingTop:'3px'}}>

      <Box>
          <Typography variant="h6" sx={{ fontFamily:'HelveticaNeue', width:'100%', color:'black'}} className={classes.marquee}>
          {text}
          </Typography>
      </Box>
    </Box>
    
  );
};

export default Marquee;