import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Button } from '@mui/material';


const data = [
    [37.8, 11.9, 'A', '-',      '1/2'],
    [39.1, 12.3, 'B', '-',      '1'],
    [40.4, 12.7, 'C', '-',      '1 1/2'],
    [41.7, 13.1, 'D', '41 1/2', '2'],
    [42.9, 13.5, 'E', '42 3/4', '2 1/2'],
    [44.2, 13.9, 'F', '44',     '3'],
    [45.5, 14.3, 'G', '45 1/4', '3 3/8'],
    [46.8, 14.7, 'H', '46 1/2', '3 3/4'],
    [48.0, 15.1, 'I', '47 3/4', '4 1/4'],
    [48.7, 15.5, 'J', '49',     '4 5/8'],
    [50.0, 15.9, 'K', '50',     '5 1/8'],
    [51.2, 16.3, 'L', '51 3/4', '5 1/2'],
    [52.5, 16.7, 'M', '52 3/4', '6'],
    [53.8, 17.1, 'N', '54',     '6 1/2'],
    [55.1, 17.5, 'O', '55 1/4', '7'],
    [56.3, 17.9, 'P', '56 1/2', '7 1/2'],
    [57.2, 18.2, 'Q', '57 3/4', '8'],
    [58.9, 18.6, 'R', '59',     '8 5/8'],
    [60.2, 19.1, 'S', '60 1/4', '9 1/8'],
    [61.4, 19.5, 'T', '61 1/2', '9 5/8'],
    [62.7, 20.0, 'U', '62 3/4', '10 1/4'],
    [64.0, 20.3, 'V', '63',     '10 5/8'],
    [65.3, 20.7, 'W', '65',     '11 1/8'],
    [66.6, 21.1, 'X', '66 1/4', '11 5/8'],
    [67.8, 21.5, 'Y', '67 1/2', '12'],
    [68.5, 21.5, 'Z', '68 3/4', '12']
];

const RingTable = () => {
    const [displayAll, setDisplayAll] = useState(false);
    return (
        <Box sx={{ flexDirection: 'column', display: 'flex', width:'100%', maxWidth:'800px' }}>
            <TableContainer component={Paper} sx={{ maxHeight: displayAll ? "1500px" : "600px", transition: 'max-height 0.5s ease-in-out', borderRadius: "20px", border:'1px solid white' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'background.paper', maxHeight:'20px', height:'20px' }}>
                            <TableCell align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#4b4b4b', borderRight:'1px solid white', borderBottom:'1px solid #797979', borderRadius: "20px 0 0 0"}}>Circumference</TableCell>
                            <TableCell align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#4b4b4b', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>Inside Diameter</TableCell>
                            <TableCell align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'60%', color:'#797979', backgroundColor:'#4b4b4b', borderBottom:'1px solid #797979', borderRadius: "0 20px 0 0"}} colSpan={3}>Ring Sizes</TableCell>
                        </TableRow>
                        <TableRow sx={{ position: 'sticky', top: '56px', zIndex: 1, backgroundColor: 'background.paper' }}>
                            <TableCell align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>(mm)</TableCell>
                            <TableCell align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>(mm)</TableCell>
                            <TableCell align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>UK</TableCell>
                            <TableCell align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>Europe</TableCell>
                            <TableCell align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderBottom:'1px solid #797979'}}>USA</TableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell key={0} align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>{row[0]}</TableCell>
                                <TableCell key={1} align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>{row[1]}</TableCell>
                                <TableCell key={2} align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>{row[2]}</TableCell>
                                <TableCell key={3} align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderRight:'1px solid white', borderBottom:'1px solid #797979'}}>{row[3]}</TableCell>
                                <TableCell key={4} align="center" sx={{maxHeight:'inherit', height:'inherit', fontFamily:'Poppins', width:'20%', color:'#797979', backgroundColor:'#060606', borderBottom:'1px solid #797979'}}>{row[4]}</TableCell>
                            
                                {/* {row.map((cell, colIndex) => (
                                    <TableCell align="center" key={colIndex} width={'20%'}>
                                        {cell}
                                    </TableCell>
                                ))} */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                variant="outlined"
                onClick={() => setDisplayAll(!displayAll)}
                sx={{
                    width: '50%',
                    margin: '20px auto',
                    color: '#4b4b4b',
                    borderColor: '#4b4b4b',
                    '&:hover': {
                        borderColor: '#797979',
                        color: '#797979',
                    },
                }}>
                {displayAll ? 'Show less' : 'Show more'}
            </Button>
        </Box>
    );
};

export default RingTable;
